<template>
  <div class="artist_search_custom">
    <artist-search @handleSelect="chooseArtist" style="width: 200px;display: inline;margin-right: 10px"
                   v-if="showSearchTool"/>
<!--        <DeptCascaderScope :type="type" ref="refDeptCascaderScope" @selectedDept="selectDept"-->
<!--                           @firstGroup="currentGroup"-->
<!--        />-->
    <CompanySelect :type="type" @handleSelect="selectDept" ref="refDeptCascaderScope"
                   :showFirstGroup="true" style="width: 200px;display: inline;margin-right: 10px"/>

    <div style="display: inline">

      <el-input v-model="searchCondition.artistName"
                placeholder='红人昵称'
                @change="getGroupArtists"
                style="width: 150px;margin-right:10px"
                clearable />

      <el-switch v-model="searchCondition.sign_status" active-text="在约" active-value="Y" inactive-text="解约"
                 inactive-value="N" @change="getGroupArtists"></el-switch>
      &nbsp;
      <template v-if="artists.length>0">
        <el-radio-group v-model="searchCondition.artist_id"
                        @change="changeRadio"
                        v-if="artists.length<=8" size="mini">
          <el-radio-button :label="artist.id" v-for="(artist,idx) in artists" :key="idx">{{
              artist.nickname
            }}
          </el-radio-button>
        </el-radio-group>
        <el-select v-else v-model="searchCondition.artist_id"  @change="changeRadio" clearable filterable>
          <el-option v-for="(artist,idx) in artists" :key="idx" :value="artist.id" :label="artist.nickname"></el-option>
        </el-select>
      </template>
      <span v-else>暂无</span>
      &nbsp;
      <el-tag type="warning" size="medium" effect="plain" v-if="artists.length>0"> {{ artists.length }} 个红人</el-tag>
    </div>
  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'

export default {
  name: "ArtistSearchCustomNickName",
  components: {
    ArtistSearch,
  },
  props: {
    showFirstGroup: {
      type: Boolean,
      default() {
        return false
      }
    },
    type: {
      type: String,
      default: ""//revenue_data revenue_enter trend_data trend_enter
    },
    showSearchTool: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data() {
    return {
      searchCondition: { artist_id: null, nickname: null, group_id: null, group_name: null, sign_status: 'Y', artistName: null },
      artistPlatforms: {},
      artists: []
    }
  },
  methods: {
    async chooseArtist(item) {
      if (item) {
        this.searchCondition.group_id = item.group_id
        this.searchCondition.group_name = item.group_name
        await this.showSearchInfo()
        this.searchCondition.artist_id = item.id
        this.searchCondition.nickname = item.nickname
      } else {
        this.searchCondition.artist_id = null
        this.searchCondition.nickname = null
        this.searchCondition.group_id = null
        this.searchCondition.group_name = null

      }
      await this.handleSelect()
    },
    async selectDept(val) {
      if (val) {
        this.searchCondition.group_id = val
        this.searchCondition.group_name = this.$refs['refDeptCascaderScope'].getNodeName(val)
        // console.log(this.searchCondition)
      } else {
        this.searchCondition.group_id = null
        this.searchCondition.group_name = null
        this.artists = []
      }
      await this.getGroupArtists()
    },
    async changeRadio(val) {
      this.searchCondition.artist_id = val
      let artist = this.artists.find((value) => value.id === val)
      this.searchCondition.nickname = artist ? artist.nickname : ''

      await this.handleSelect()
    },
    async handleSelect() {
      // 检索红人昵称
      this.$emit('handleSelect', this.searchCondition)
    },
    async currentGroup(group) {
      if (group && group.dpt_id) {
        await this.setGroupInfo(group.dpt_id, group.name)
      }
    },
    async setGroupInfo(group_id, group_name) {
      this.searchCondition.group_id = group_id;
      this.searchCondition.group_name = group_name;
      this.artists = []
      await this.getGroupArtists()
    },
    async getGroupArtists(type) {
      this.searchCondition.artist_id = null
      this.searchCondition.nickname = null
      let artistName = this.searchCondition.artistName
      let groupId = artistName ? null : this.searchCondition.group_id
      let sign_status = this.searchCondition.sign_status
      if (groupId || artistName) {
        let { list } = await this.$api.getGroupArtistsByNickname(groupId, sign_status, artistName)
        this.artists = list
        if (type !== 'search' && list && list.length > 0) {
          this.searchCondition.artist_id = list[0].id
          this.searchCondition.nickname = list[0].nickname
        }
      }
      if (type !== 'search')
        await this.handleSelect()
    },
    async showSearchInfo() {
      await this.getGroupArtists('search')
    }
  }
}
</script>

<style scoped>
/*.artist_search_custom {*/
/*  border: red 1px dashed;*/
/*  width: 100%;*/
/*}*/

</style>
