<template>
  <div>
    <!--    <router-view v-show="$route.matched.length==3"></router-view>-->
    <PageHeaderLayout>
      <div class='main-page-content'>
        <el-row>
          <el-col :span='24'>
            <el-form size='mini' :inline='true'>
              <el-form-item label=''>
                <el-radio-group v-model='searchCondition.is_all' @change='handleClick'>
                  <el-radio-button label='Y'>全平台</el-radio-button>
                  <el-radio-button label='N'>开通平台</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label=''>
                <el-date-picker v-model='searchCondition.year' type='year' placeholder='选择年' size='mini'
                                :clearable='false'
                                format='yyyy年'
                                value-format='yyyy'
                                style='width: 100px' @change='handleClick' />
              </el-form-item>
              <el-form-item>
                <artist-search-custom-nickname :type='`revenue_enter`' :show-search-tool='false'
                                               @handleSelect='selectArtist'
                                               :show-first-group='true' style='width: 100%' />
              </el-form-item>
              <el-form-item>
                <artist-search @handleSelect='handleSelectKol' v-if='userPermissions.indexOf("revenue_enter_all_groups")>-1'></artist-search>
              </el-form-item>
              <el-form-item>
                <el-button plain round icon='el-icon-user-solid' @click='handleClick' type='primary'>{{searchCondition.nickname||''}}</el-button>
              </el-form-item>
              <el-form-item style='float: right' v-if="userPermissions.indexOf('revenue_log') !== -1">
                <el-button @click='showLogEvent' :disabled='!searchCondition.artist_id'><i
                  class='el-icon-arrow-left'></i>日志
                </el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <!--        <div class='current-box'>-->
        <!--          <span style='margin-left: 10px;'>{{ searchCondition.nickname || '&#45;&#45;' }}</span>-->
        <!--          &nbsp;-->
        <!--          &nbsp;-->
        <!--          &nbsp;-->
        <!--          &nbsp;-->
        <!--          &lt;!&ndash;          <template>&ndash;&gt;-->
        <!--          &lt;!&ndash;            <platform-logos :platforms='platforms'></platform-logos>&ndash;&gt;-->
        <!--          &lt;!&ndash;          </template>&ndash;&gt;-->
        <!--        </div>-->
        <!--        数据录入-->
        <RevenueRecord ref='RevenueRecord' :platforms='platforms' :search-condition='searchCondition' />
        <!--        操作日志-->
        <RevenueDataLog ref='refLog' :searchCondition='searchCondition'></RevenueDataLog>


      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import RevenueRecord from '@/pages/revenue/enter/RevenueRecord'
import RevenueDataLog from '@/pages/revenue/RevenueDataLog'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import ArtistSearchCustomNickname from '@/components/artist/ArtistSearchCustomNickname.vue'
import PlatformLogos from '@/components/Platform/PlatformLogos'
import ArtistSearch from '@/components/artist/ArtistSearch'

export default {
  name: 'RevenueEnterList',
  components: {
    ArtistSearch,
    PlatformLogos,
    RevenueDataLog,
    PageHeaderLayout,
    ArtistSearchCustom,
    ArtistSearchCustomNickname,
    ApeTable,
    ApeDrawer,
    RevenueRecord
  },
  data() {
    return {
      loadingStatus: false,
      platforms: [],
      searchCondition: {
        is_all: 'N',
        year: null,
        group_id: null,
        group_name: null,
        artist_id: null,
        nickname: null
      },
      explainList: [
        { title: '默认1', explain: '本数据也用于本组绩效核算，请务必仔细核对后再进行提交', 'auth': '' },
        { title: '默认2', explain: '请录入本月及本月之前的数据，本月之后的单元格默认非编辑状态，但不强制不可编辑', 'auth': '' }
      ],
      showLog: false,
      is_loading: false
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  created() {
    this.getPageSettings()

  },
  mounted() {
  },
  watch: {
    '$route.matched': function(n) {
      if (n.length === 2) {
        // this.initList()
      }
    }
  },
  methods: {
    showLogEvent() {
      this.$refs['refLog'].show(this.searchCondition)
    },
    handleSelectKol(row) {
      // this.$notify.info(JSON.stringify(row))
      this.searchCondition.artist_id = row.id || null
      this.searchCondition.nickname = row.nickname || null
      this.handleClick()
    },
    handleClick() {
      // console.log(this.searchCondition)
      if (this.searchCondition.artist_id && this.searchCondition.year)
        this.$refs.RevenueRecord.initData({
          artist_id: this.searchCondition.artist_id,
          year: this.searchCondition.year,
          is_all: this.searchCondition.is_all
        })
    },
    async selectArtist(selectInfo) {
      this.searchCondition.artist_id = selectInfo.artist_id || null
      this.searchCondition.nickname = selectInfo.nickname || null
      // this.searchCondition.group_id = selectInfo.group_id || null
      // this.searchCondition.group_name = selectInfo.group_name || null
      // this.searchCondition = { ...this.searchCondition, ...selectInfo }
      this.handleClick()
    },
    getCurrentYear() {
      let currentDate = new Date()
      this.searchCondition.year = currentDate.getFullYear() + ''
    },
    async getPageSettings() {
      this.getCurrentYear()

      //页面配置-依次加载 我的分组-》红人-》平台-》当前月份
      await this.getPlatforms()
    },
    async getPlatforms() {
      //从缓存中读取上次的平台信息
      let platformsJson = localStorage.getItem('platforms')
      if (platformsJson && platformsJson.indexOf('{') > -1) {
        this.platforms = JSON.parse(platformsJson)
      }
      // 获取最新平台信息
      let { list } = await this.$api.getPlatforms()
      this.platforms = list
      // 缓存平台信息
      localStorage.setItem('platforms', JSON.stringify(list))
    }
  }
}
</script>

<style scoped>
.explain-title {
  color: #909399;
  font-weight: 800;
}

.explain {
  color: #E6A23C;
}

.explain-auth {
  color: #409EFF;
  margin-left: 5px;
}

.explain-auth::before {
  content: '@';
}

.box-card {
  margin-top: 10px;
}

.current-box {
  border: #e97ca1 1px dashed;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 5px 0;
  text-align: left;
  display: flex;
  align-items: flex-start;
  vertical-align: middle;
}
</style>
<style>

.platforms-tab .el-tabs__item {
  display: block;
  margin: 1px 1px;
  line-height: 25px;
  font-size: 1rem;
  /*word-wrap: break-word;*/
  writing-mode: horizontal-tb;
  height: 80px;
  padding-left: 10px;
  padding-top: 10px;
}
</style>
