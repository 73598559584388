<template>
  <div class='default-table tableBox'
       v-loading='loadingStatus'
       element-loading-text='数据加载中……'
       element-loading-spinner='el-icon-loading'>

    <el-table border :data='tblData'
              :span-method='objectSpanMethod'
              :highlight-current-row='true'
              @row-click='selectRow'
              height='100%'
              :key='searchCondition.year'
              v-if='searchCondition.year'>
      <el-table-column prop='platform' label='#' min-width='50' align='center'>
        <template slot-scope='scope'>
          <div v-if='scope.row.platform_code' class='platform-logo'>
            <el-avatar shape='square' :size='30'
                       :src='getLogoUrl(scope.row.platform_code)'
                       @error='true'>
              <img src='https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png' />
            </el-avatar>
            <br />
          </div>
          <b>{{ scope.row.platform }}</b>
        </template>
      </el-table-column>
      <el-table-column prop='income_type' label='收入分类' align='center' min-width='100'>
        <template slot-scope='scope'>
          <b>{{ scope.row.income_type }}</b>
        </template>
      </el-table-column>

      <template v-for='i in 12'>
        <el-table-column :prop='`month_${i}`' :key='i' :label='`${i}月`' :min-width='`${showEditCell(i)?150:100}`'
                         align='right'
                         header-align='center'>
          <template slot='header'>
            <span v-if='searchCondition.year' :class="showEditCell(i)?'curr-month':''">{{ i }}月</span> &nbsp;
            <el-link @click='handleSync(i)' icon='el-icon-refresh'
                     v-if='searchCondition.year&&showSync(searchCondition.year,i)'></el-link>
          </template>
          <template slot-scope='scope'>
            <!--      合计部分不能编辑      -->
            <b v-if='scope.$index>=tblData.length-3'>
              {{ moneyFormat(scope.row[`month_${i}`]) }}
            </b>
            <div v-else>
              <editable-cell
                :show-input='showEditCell(i)&&editDisableFields.indexOf(scope.row.category)===-1'
                v-model='scope.row[`month_${i}`]'
                :is-input-number='true' @change='selectInput(scope.row,`month_${i}`)'>
              <span slot='content'>
                <span>{{ $utils.numberFormat(scope.row[`month_${i}`],2,'.',',')}}</span>
              </span>
              </editable-cell>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <div
      style='border-radius:5px;color:#ff8108;border: #ff8108 1px dashed;line-height: 20px;display: inline-block;margin-top: 5px;padding-left: 10px'
      v-show='showTip'>
      <span>注意：选择红人后显示录入表格</span>

      <el-link @click='showTip=!showTip' style='float: right;margin: 0 20px'><i class='el-icon-close'></i></el-link>
    </div>
  </div>

</template>

<script>
import EditableCell from '@/components/EditableCell.vue'
import { numberFormat } from '@/utils/utils'


export default {
  name: 'RevenueRecord',
  components: {
    EditableCell
  },
  props: {
    platforms: {
      type: Array,
      default: () => {
        return []
      }
    }
    // searchCondition: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       is_all: null,
    //       year: null,
    //       group_id: null,
    //       group_name: null,
    //       artist_id: null,
    //       nickname: null,
    //     }
    //   },
    // }
  },
  watch: {
    // 表格数据
    platforms: function() {
      let map = {}
      this.platforms.forEach((platform) => {
        map[platform.code] = platform
      })
      this.platformMap = map
    }
    // 'searchCondition.is_all'() {
    //   this.initData()
    // },
    // 'searchCondition.artist_id'() {
    //   this.initData()
    // },
    // 'searchCondition.year'() {
    //   this.initData()
    // }
  },
  data() {
    return {
      dataList: [],
      searchCondition: {},
      loadingStatus: false,
      showTip: true,
      offset: 0,
      platformMap: {},
      fields: [],
      currentYear: null,
      currentMonth: null,
      editMode: false,//单元格是否编辑模式
      editModeMap: { editMode_1: false },//单元格是否编辑模式
      mergeFields: ['platform'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      //默认非输入框的列 'income_8', 'income_1'
      editDisableFields: ['income_8', 'income_1'],
      //同步开始年月（功能上线8月，7月份的数据开始）
      beginYear: 2024,
      beginMonth: 7,
    }
  },
  computed: {
    // 表格数据
    tblData() {
      // 增加三行数据
      let revenueRow = { platform: '总营收', income_type: '总营收' }
      // revenueRow['platform'] = '总营收'
      let costRow = { platform: '总推广花费', income_type: '总推广花费' }
      // costRow['platform'] = '总推广花费'
      let incomeRow = { platform: '当期盈亏', income_type: '当期盈亏' }
      // incomeRow['platform'] = '当期盈亏'
      if (this.dataList && this.dataList.length > 0) {
        this.dataList.forEach((row) => {

          let category = row['category']
          for (let i = 1; i <= 12; i++) {
            let incomeField = 'month_' + i
            let val = row[incomeField]
            //总营收 = 商务营收 + 短视频佣金 + 直播佣金 + 直播坑位费 + 授权收入
            if (['income_1', 'income_3', 'income_4', 'income_5'].indexOf(category) > -1) {
              if (!revenueRow[incomeField]) {
                revenueRow[incomeField] = 0
              }
              revenueRow[incomeField] += val * 1
            }
            // 总推广花费 = 推广花费
            if (['income_7'].indexOf(category) > -1) {
              if (!costRow[incomeField]) {
                costRow[incomeField] = 0
              }
              costRow[incomeField] += val * 1
            }
            //当期盈亏 = 当期总营收 - 当期总推广花费
            incomeRow[incomeField] = (revenueRow[incomeField] - costRow[incomeField])
          }
        })
        let appendRows = [
          revenueRow,
          costRow,
          incomeRow
        ]
        return [...this.dataList, ...appendRows]
      } else {
        return []
      }
    }
  },
  created() {
    for (let i = 1; i <= 12; i++) {
      let incomeField = 'month_' + i
      this.fields.push(incomeField)
    }
    let day1 = new Date()
    this.currentYear = day1.getFullYear()
    this.currentMonth = day1.getMonth() + 1
  },
  // mounted() {
  //   // 监听 ctrl+v键盘事件
  //   document.addEventListener('paste', this.getPasteText, false)
  // },
  // beforeDestroy() {
  //   document.removeEventListener('paste', this.getPasteText, false)
  // },
  methods: {
    showEditCell(month) {
      const currYear = (new Date()).getFullYear()
      const currMonth = (new Date()).getMonth() + 1
      const year = this.searchCondition.year
      const diffMonths = this.dffStartMonths(year, month, currYear, currMonth)
      //仅显示上个月的单元格为可编辑
      return diffMonths === -1
    },
    showSync(year, month) {
      if (year - this.beginYear > 0) {
        return true
      } else {
        // console.log(`year:${year},month:${month}`)
        const diffMonths = this.dffStartMonths(year, month, this.beginYear, this.beginMonth)
        // console.log(`month:${month},diff:${diffMonths}`)
        return diffMonths >= 0
      }

    },
    handleSync(month) {
      const diffMonths = this.dffStartMonths(this.searchCondition.year, month, this.beginYear, this.beginMonth)
      if (diffMonths >= 0) {
        this.$notify.info(`开始同步【${this.searchCondition.year}年${month}月】的保量收入、商务营收`)
        this.is_loading = true
        //手动同步数据
        this.$api.syncRevenueData({
          artist_id: this.searchCondition.artist_id,
          year: this.searchCondition.year,
          month: month
        })
        setTimeout(() => {
          this.is_loading = false
          this.getRevenueDataList()
        }, 5000)
      } else {
        this.$notify.warning(`【${this.beginYear}年${this.beginMonth}月】开始才可同步数据！！！`)
      }
    },
    dffStartMonths(year, month, beginYear, beginMonth) {

      //当前月份
      // const currYear = (new Date()).getFullYear()
      // const currMonth = (new Date()).getMonth() + 1
      return (Math.abs(year * 1) - beginYear * 1) * 12 + (month * 1 - beginMonth * 1)
    },
    moneyFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    async getRevenueDataList() {
      this.loadingStatus = true
      const searchCondition = this.handleSearchCondition()
      let { list } = await this.$api.getRevenueDataList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
        this.rowspan() //合并单元格的数据依据，用于  :span-method="objectSpanMethod"
      })
      this.loadingStatus = false
      this.setEditModes()//设置编辑模式
    },
    // eslint-disable-next-line no-unused-vars
    initData(search) {
      this.dataList = []
      this.searchCondition = { ...search }
      let searchCondition = this.searchCondition
      if (searchCondition.year && searchCondition.artist_id) {
        this.getRevenueDataList()
      }
    },
    //保存单元格数据
    async saveCell(row, field) {
      let data = {}
      if (row.id) {
        data['id'] = row.id
      }

      let appendFields = {
        year: this.searchCondition.year,
        // group_id: this.searchCondition.group_id,
        artist_id: this.searchCondition.artist_id,
        _current_field: field
      }
      data = { ...row, ...appendFields }
      let id = await this.$api.saveRevenueData(data)
      if (!id) {
        row[field] = null
        this.$notify.error('保存失败！')
      }
    },
    async selectInput(row, field) {
      // 兼容整行-数据复制粘贴
      // console.log(row[field])
      if (row[field] && row[field].length > 0)
        this.saveCell(row, field)
      else {
        row[field] = 0
        this.saveCell(row, field)
      }
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.is_all) {
        condition.is_all = this.searchCondition.is_all
      }
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      return condition
    },
    rowspan() {
      const fields = this.mergeFields //合并的字段
      this.tblData.forEach((item, index) => {
        fields.forEach((field) => {
          if (index === 0) {
            this.spanObj[field] = []
            this.spanObj[field].push(1)
            this.positionObj[field] = 0
          } else {
            // 合并数据：若和上一行的数据相同，则该字段合并行数加1
            if (this.tblData[index][field] === this.tblData[index - 1][field]) {
              this.spanObj[field][this.positionObj[field]] += 1
              this.spanObj[field].push(0)
            } else {
              this.spanObj[field].push(1)
              this.positionObj[field] = index
            }
          }
        })
      })
    },

    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      //末尾3行合并列：
      if (rowIndex >= this.tblData.length - 3 && rowIndex <= this.tblData.length - 1) {
        if (columnIndex === 0) {
          return [0, 0]
        } else if (columnIndex === 1) {
          return [1, 2]
        } else {
          return [1, 1]
        }
      } else {
        const fields = this.mergeFields
        if (column.property && fields.indexOf(column.property) > -1) {
          const _row = this.spanObj[column.property][rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col
          }
        }
      }
    },
    getLogoUrl(platform_code) {
      let logo_url = this.platformMap[platform_code] ? this.platformMap[platform_code]['logo_url'] : ''
      return logo_url
    },
    canEditCell(month) {
      if (this.searchCondition.year < this.currentYear) {
        return true
      } else if (this.searchCondition.year === this.currentYear) {
        if (month <= this.currentMonth) {
          return true
        } else {
          return false
        }
      } else {
        console.log('editable', 0)
        return false
      }

    },
    //获取选中单元格信息
    selectRow(row, column) {
      this.currentRow = row
      this.currentColumn = column
      // if (column)
      //   console.log(`${ event.type } ${ column.property }`)

    },
    //设置单元格是否可编辑
    setEditModes() {
      for (let i = 1; i <= 12; i++) {
        let monthEditMode = 'editMode_' + i
        this.editModeMap[monthEditMode] = false

        if (this.searchCondition.year < this.currentYear) {
          this.editModeMap[monthEditMode] = true
        } else if (this.searchCondition.year > this.currentYear) {
          this.editModeMap[monthEditMode] = false
        } else {
          if (i <= this.currentMonth) {
            this.editModeMap[monthEditMode] = true
          } else {
            this.editModeMap[monthEditMode] = false
          }
        }
      }
    },
    // 获取粘贴文本处理：若有多列数据，则询问确认粘贴开始列
    getPasteText(event) {
      let clipboardData = (event.clipboardData || window.clipboardData)
      const text = clipboardData.getData('text')
      let arrText = text.trim().split(/ [(\r\n)\r\n] /).join().split('\t') // 以转行符切割文本字符串
      if (arrText.length > 1) {
        //当前列的字段
        let field = this.currentColumn.property
        let label = this.currentColumn.label || '未知'
        let startIndex = this.fields.indexOf(field)
        let fields = this.fields
        let pasteText = arrText.join('\t')
        let confirmMsg = `整行列粘贴：[${pasteText}]，是否确认?`
        //计算从哪列开始填充
        if (startIndex > -1) {
          //若选中的是可填充列，则从选中列开始往后填充，否则，填充整列
          let len = arrText.length
          fields = this.fields.slice(startIndex, len + startIndex)
          confirmMsg = `从列【${label}】开始粘贴：[${pasteText}]，是否确认?`
        }

        this.$confirm(confirmMsg, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.saveRow(fields, arrText)
          // clipboardData.setData('text', '')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      }
    },
    async saveRow(fields, arrText) {
      for (let i = 0; i < fields.length; i++) {
        let f = fields[i]
        this.currentRow[f] = arrText[i].trim()
        if (this.currentRow.id) {
          this.saveCell(this.currentRow, f)
        } else
          await this.saveCell(this.currentRow, f)
      }
    }
  }
}
</script>

<style scoped>
.tableBox {
  height: 80vh;
}
</style>
<style>
.el-select > .el-input {
  width: 200px !important;
}

.curr-month {
  /*border: #e97ca1 1px dashed;*/
  /*width: 200px!important;*/
  font-size: 1.5em;
  color: #ff3176;
}
.edit-cell:hover {
  cursor: pointer;
}
</style>
